<template>
  <canvas
    ref="canvas"
    class="confetti-canvas absolute inset-0" />
</template>

<script lang="ts" setup>
import confetti from "canvas-confetti";

const canvas = ref<HTMLCanvasElement>();
const store = useCanvasStore();
const confettiInstance = ref<confetti.CreateTypes>();

const colors = [
  "#e66a35",
  "#f2c94c",
  "#eb5757",
  "#ff9a9e",
];

const triggerFireworks = () => {
  const duration = 5 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 300, scalar: 0.5, ticks: 60, zIndex: 0, colors: ["#fff", ...colors] };

  function randomInRange ( min, max ) {
    return Math.random() * ( max - min ) + min;
  }

  var interval = setInterval( function() {
    const timeLeft = animationEnd - Date.now();

    if ( timeLeft <= 0 ) {
      return clearInterval( interval );
    }

    const particleCount = 300 * ( timeLeft / duration );
    // since particles fall down, start a bit higher than random
    confettiInstance.value( { ...defaults, particleCount, origin: { x: randomInRange( 0.1, 0.3 ), y: Math.random() - 0.5 } } );
    confettiInstance.value( { ...defaults, particleCount, origin: { x: randomInRange( 0.7, 0.9 ), y: Math.random() - 0.5 } } );
  }, 250 );
};

onMounted( () => {
  if ( !canvas.value ) {
    throw createError( "Unable to get canvas" );
  }

  confettiInstance.value = confetti.create( canvas.value, {
    resize: true,
    useWorker: false,
    disableForReducedMotion: true,
  } );

  watch( () => store.fireworks, ( newVal ) => {
    if ( newVal ) {
      triggerFireworks();
    }
  } );
} );
</script>

<style scoped>
.confetti-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
